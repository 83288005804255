/**
* This file is part of AudioBot Project
* Copyright (C) 2024 W3villa Technologies
* 
* This program is free software: you can redistribute it and/or modify
* it under the terms of the GNU Affero General Public License as
* published by the Free Software Foundation, either version 3 of the License, or
* (at your option) any later version.
* 
* This program is distributed in the hope that it will be useful,
* but WITHOUT ANY WARRANTY; without even the implied warranty of
* MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
* GNU Affero General Public License for more details.
* 
* You should have received a copy of the GNU Affero General Public License
* along with this program. If not, see <https://www.gnu.org/licenses/agpl-3.0.txt>.
*/

@import "react-chat-elements/dist/main";
@import 'bootstrap/dist/css/bootstrap.min.css';
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');

* {
    font-family: "Outfit", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
}


// Color variables

$themePrimary: #6739FF;
$secondary: #DDEDFF;

body,
#root {
    position: relative;
    overflow: hidden;
}

@keyframes blink {

    0%,
    100% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }
}

.border-radius-50 {
    border-radius: 30px;
}

.font-14 {
    font-size: 14px;
}

.otpInput {
    input {
        text-align: center;
        font-size: 30px;
        height: 60px;
        font-weight: bold;

        &::placeholder {
            color: #f1f1f1;
        }

        &:focus {
            outline: unset;
            box-shadow: unset;
        }
    }

}

.avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

.remote-name {
    width: 100%;
    border: 1px solid #d3d2d2;
    padding: 5px;
    border-radius: 5px;
}

.interpreter-title {
    background: white;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    /* transform: translate(-50%); */
    padding: 5px;
    /* text-align: center; */
    border-radius: 10px 10px 0 0;
    font-size: 20px;

    .interpreter-title-text {
        padding: 0 32px;
        display: flex;
        align-items: center;
        column-gap: 15px;

        .interpreter-listening {
            color: red;
            font-size: 14px;
        }

        .interpreter-active {
            font-size: 14px;
            color: green;
        }
    }
}

.custom-tooltip {
    .tooltip-arrow::before {
        border-top-color: #e6e6e6 !important;
    }
    .tooltip-inner {
        max-width: 500px;
        background-color: #e6e6e6 !important;
        color: #000000;
        border-radius: 4px;
        font-size: 12px;
    }
}

.fileInput {
    border: 1px solid #ccc;
    width: 100%;
    padding: 8px 20px;
    border-radius: 28px;
}

input[type="file"]::-webkit-file-upload-button {
    background-color: transparent;
    border: unset;
    border-right: 1px solid #ccc;
}

.font-14 {
    font-size: 14px;
}

.w-150 {
    width: 150px;
}

.w-220 {
    width: 250px;
}

select:focus-visible {
    outline: unset;
}

.btn.btn-primary,
.btn-secondary,
.btn-success {
    border-radius: 30px;
    height: 42px;
    font-size: 14px;
}

.headWicon {
    svg {

        font-size: 70px;
        margin-bottom: 0px;
        color: #8a8a8a;
        position: relative;
        top: -10px;
    }
}

.blinking {
    animation: blink 4s linear infinite;
    font-size: 20px;
}


audio::-webkit-media-controls-enclosure {
    overflow: hidden;
}

audio::-webkit-media-controls-panel {
    width: calc(100% + 30px);
    /* Adjust as needed */
}

.home::-webkit-scrollbar {
    display: none;
}

#root {
    background-color: #F2F9FF;
}

.rce-mbox {
    // box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    border-radius: 30px !important;
    padding-left: 15px;
    padding-right: 15px;
    box-shadow: unset;
    margin-bottom: 6px;
    background-color: $secondary;
    position: relative;
    margin-left: 0;
    max-width: calc(100% - 10%);

    .rce-mbox-left-notch {
        path {
            display: none;
        }
    }

    &:before {
        content: "";
        width: 10px;
        height: 10px;
        position: absolute;
        border: 10px solid transparent;
        border-left-color: $secondary;
        bottom: -6px;
        left: 8px;
    }
}

.rce-mbox-text {
    font-weight: 200;

    &:after {
        content: unset;
    }
}

.rce-mbox-title {
    display: none;
}

.rce-mbox-audio {
    height: 30px;
    margin-left: -8px;
    margin-right: -8px;

    audio {
        height: 32px;
        position: relative;
        z-index: 99;
    }
}

.rce-mbox-title--clear {
    margin-bottom: 5px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.4);

    span {
        color: white;

    }
}

.pdf_file {
    .rce-mbox-file--buttons {
        display: none;
    }

    .rce-mbox-file {
        padding-bottom: 0;

        button {
            background: transparent;
            color: #ffffff;
        }
    }
}

.phone-input {
    input {
        &:focus-visible {
            outline: none;
        }

        border: 1px solid #dee2e6;
        border-radius: 5px;
        padding: 16px;
    }
}

.input_parent {
    background-color: white;
    padding-bottom: 20px;
    padding-top: 6px;

    .custom-input-wrap {
        border-radius: 25px;
        margin: auto;
        max-width: calc(100% - 20px);
        min-width: calc(100% - 20px);
        background-color: #f0f0f0;
        align-items: center;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;

        .mic {
            height: 24px;
            width: 40px;
            align-items: center;
            justify-content: center;
            border-right: 1px solid #ccc;
            cursor: pointer;
            display: flex;
            margin: 5px;
            position: relative;

            span {
                font-size: 14px;
            }

            svg {
                opacity: 0.5;
            }

            .mic-pause {
                position: absolute;
                left: 100%;
                background-color: #ffffff;
                padding: 5px;
                border-radius: 5px;
                width: 100px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                cursor: pointer;

                svg {
                    font-size: 20px;
                }
            }
        }

        input {
            height: 42px;
            background-color: #f0f0f0;
            font-weight: 300;
            padding: 10px 5px;
            resize: none;
            border: none;
            border-radius: 5px;
            box-sizing: border-box;
            color: #333;
            flex: 1 1;
            font-size: 14px;
            outline: none;
            margin-left: 20px;
        }

        .send {
            background: white;
            height: 40px;
            width: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 25px;
            padding: 0 16px;
            border: none;
            margin: 5px;

            svg {
                color: $themePrimary;
                font-size: 18px;
            }
        }
    }
}

.language-input-container {
    .language-input-wrap {
        display: flex;
        align-items: center;

        svg {
            font-size: 12px !important;
        }

        button {
            width: 100px;
            height: 38px;
            background-color: #f0f0f0;
            font-weight: 300;
            padding: 4px 10px;
            border: none;
            border-radius: 35px;
            box-sizing: border-box;
            text-align: center;
            cursor: pointer;
            background: white;
            margin: 0 10px;
        }

        .exchange-icon {
            font-size: 24px;
            color: $themePrimary;
            cursor: pointer;
        }
    }

    .modal {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        padding: 20px;
        border-radius: 10px;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);

        .modal-content {
            display: flex;
            flex-direction: column;
            align-items: center;

            h4 {
                margin-bottom: 20px;
            }

            button {
                background-color: $themePrimary;
                color: white;
                border: none;
                padding: 10px 20px;
                margin: 5px;
                border-radius: 5px;
                cursor: pointer;

                &:hover {
                    background-color: darken($themePrimary, 10%);
                }
            }
        }
    }
}
@media screen and (min-width: 768px){
    .showMobile{
        display: none;
    }
}
@media screen and (max-width: 767px){
    .dNone-sm{
        display: none;
    }
    .input_parent{ 
        .custom-input-wrap{ 
            .inputChat{
                margin-left: 0;
            }    
            .langIcon{
                width: 40px;
                font-size: 12px;
            }
        }
    }
}

// Define keyframes for the rotation animation
@keyframes rotateAnimation {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(180deg);
    }
}

.exchange-icon {
    transition: transform 0.5s ease;

    &.rotate {
        animation: rotateAnimation 0.5s ease forwards;
    }
}


.audio-recorder {
    box-shadow: unset;
    background: transparent;

    .audio-recorder-mic {
        opacity: 0.5;
    }
}

%flex-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.home-title {
    background-color: #ffffff;
    text-align: center;
    padding: 16px 16px;
    box-shadow: 0 -1px 4px 0 #ccc;
    height: 74px;

    .nav-wrap {
        @extend %flex-between;

        a {
            text-decoration: none;
            color: #000000;
        }

        .logo-wrap {
            @extend %flex-between;

            img {
                width: 60px;
                object-fit: contain;
            }

            h1 {
                margin: 0;
                margin-left: 15px;
                ;
                font-size: 24px;
                font-weight: 600;

                @media screen and (max-width: 767px) {
                    font-size: 18px;
                }
            }
        }

        .voice-drop {
            font-size: 14px;
            @extend %flex-between;

            .add-agent {
                a {
                    text-decoration: none;
                    color: #ffffff;
                }
            }

            select {
                border: none;
                border-radius: 5px;
                border: 1px solid #f1f1f1;
                background: white;
                padding: 14px 5px;
                height: 42px;
                padding: 0 5px;
                border-radius: 30px;

                &:hover {
                    border-color: #ccc;
                }
            }

            .agent-dropdown {
                position: relative;
                width: 180px;

                .agent-logo {
                    border: 1px solid #f1f1f1;
                    padding: 10px 5px;
                    border-radius: 50px;
                    width: 30px;
                    height: 30px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    img {
                        width: 20px;
                    }
                }

                .agent-dropdown-header {
                    @extend %flex-between;
                    border: 1px solid #f1f1f1;
                    padding: 5px 10px;
                    cursor: pointer;
                    border-radius: 5px;
                    margin-right: 5px;
                    font-size: 14px;
                    border-radius: 30px;
                }

                .agent-dropdown-options {
                    list-style-type: none;
                    padding: 0;
                    margin: 0;
                    box-shadow: 0 2px 6px 0 #ccc;
                    background-color: white;
                    position: absolute;
                    width: 100%;
                    top: 100%;
                    z-index: 99;

                    li {
                        padding: 10px;
                        cursor: pointer;

                        .li-item {
                            @extend %flex-between;
                        }
                    }

                    li:hover {
                        background-color: #e0e0e0;
                    }

                    li.selected {
                        background-color: $themePrimary;
                        color: white;
                    }
                }
            }
        }
    }
}

.chatWrap {
    max-width: 830px;
    margin: auto;
    padding-left: 15px;
    padding-right: 15px;
    position: relative;

    .interpreter-btn-wrap {
        position: absolute;
        top: 0px;
        display: flex;
        flex-direction: column;
        align-items: center;
        right: -170px;
        z-index: 9;
        row-gap: 15px;


        .interpreter-btn {
            border-radius: 20px;
            box-shadow: 0 2px 6px 0 #ccc;
            background: white;
            border: none;
            padding: 10px;
            width: 180px;
            text-align: center;
        }
        @media screen and (max-width: 1176px){
            position: fixed;
            right: 0;
            top: 120px;
            .interpreter-btn{
                border-radius: 20px 0 0 20px;
                font-size: 12px;
                width: 150px;
                text-align: left;

            }
        }
        @media screen and (max-width: 767px){
            top: 178px;
            .interpreter-btn{
                width: 45px;
                font-size: 16px;
                text-align: center;
            }
        }
    }

    .bg-music {
        position: absolute;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-color: green;
        text-align: center;
        line-height: 50px;
        color: white;
        font-size: 32px;
        z-index: 9;
        right: 30px;
        top: 10px;
        cursor: pointer;
    }

    @media screen and (max-width: 790px) {
        width: 100%;
        padding-left: 0;
        padding-right: 0;
    }
}

.rce-mlist {
    .processing {
        text-align: center;

        .rce-mbox {
            background-color: transparent;
            width: 100%;
            max-width: 100%;
            margin-right: 0;

            .rce-mbox-text {
                &:after {
                    content: unset;
                }
            }

            &:before {
                content: unset;
            }
        }
    }
}

.mobile-warnning {
    position: relative;
    height: 58px;
    top: 0;
    width: 100%;
    margin: auto;
    left: 0;
    right: 0;
    font-size: 12px;
    text-align: center;
    padding: 10px;
    // border-radius: 10px;
    z-index: 9;
    border: 1px solid;
    color: #856404;
    background-color: #fff3cd;
    border-color: #ffeeba;
}

.call-thank-wrap {
    width: 60%;
    margin: auto;
    text-align: center;
    background-color: white;
    position: relative;
    border-radius: 15px;

    .thank-content {
        font-size: 3rem;
        font-weight: 500;
    }

    .tick-wrap {
        width: 100px;
        margin: auto;

        img {
            width: 100%;
        }
    }
}

@media screen and (min-width: 768px) {
    .mobile-warnning {
        display: none;
    }
}

.interpret-modal {
    .modal-body {
        max-height: calc(100vh - 400px);
        overflow-x: auto;
        position: relative;

        button {
            width: 250px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }

        input {
            position: sticky;
            top: -16px;
            z-index: 1;
            background-color: #ffffff;
        }
    }
}

.home-wrap {
    margin-top: 30px;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.0509803922);
    border-radius: 40px 40px 0 0;
    animation: flip .5s;

    .interpreter {
        height: 50%;
        width: 97%;
        background-color: #f4f7fa;
        margin: 10px;
        border-radius: 30px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;

        .interpreter-text-wrap {
            flex-grow: 1;
            width: 100%;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 32px;
            flex-direction: column;
        }


        .language-section {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px 20px;
            margin-bottom: 10px;
            background: white;
            width: 62%;
            border-radius: 50px;

            .icon-volume {
                font-size: 24px;
                color: #3b82f6;
                margin-right: auto;
                width: 55px;
                background: #f1f1f1;
                height: 55px;
                padding: 15px;
                box-sizing: border-box;
                border-radius: 30px;
            }

            .icon-mic {
                font-size: 24px;
                color: #3b82f6;
                margin-left: auto;
                cursor: pointer;
                width: 55px;
                background: #f1f1f1;
                height: 55px;
                padding: 15px;
                box-sizing: border-box;
                border-radius: 30px;
            }

            .icon-mic.active {
                color: red;
            }

            .interpreter-lang-input {
                margin: 0 10px; // Add some space between icons and input

                input {
                    width: 300px;
                    padding: 15px;
                    border: 1px solid #ccc;
                    border-radius: 30px;
                    font-size: 16px;
                    outline: none;
                    transition: border-color 0.3s;
                    cursor: pointer;
                    background: #f4f7fa;
                    text-align: center;

                    &:focus {
                        border-color: #3b82f6;
                    }
                }
            }
            
        }
        @media screen and (max-width: 767px){
            .language-section{
                width: 283px;
                padding-left: 15px;
                padding-right: 15px;
                .icon-volume, .icon-mic{
                    height: 35px;
                    min-width: 35px;
                    width: 35px;
                    padding: 10px;
                }
            }
            .interpreter-lang-input{
                input{
                    width: auto !important;
                    font-size: 14px !important;
                    padding: 5px 10px !important;
                }
            }
        }
        @media screen and (max-width: 450px){
            .language-section{
                width: auto;
                margin-left: auto;
                margin-right: auto;
            }
        }
    }


    .flipContent {
        height: calc(100vh - 104px);
        background-color: white;
        border-radius: 40px 40px 0 0;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-around;
        @media screen and (max-width: 767px){
            height: calc(100dvh - 162px)
        }
    }

    .home {
        height: calc(100dvh - 424px);
        overflow-y: auto;
        background: white;
        position: relative;

        .rce-container-mlist {
            padding: 20px;

            @media screen and (max-width: 767px) {
                padding-left: 15px;
                padding-right: 15px;
            }
        }

        .rce-mbox.rce-mbox-right {
            background-color: $themePrimary;
            color: white;
            max-width: calc(100% - 10%);
            margin-right: 0;

            .rce-mbox-right-notch {
                fill: $themePrimary;
                transform: rotate(90deg);
                top: unset;
                bottom: -6px;
                right: 9px;
                filter: unset;
            }

            &:before {
                content: unset;
            }
        }

        @media screen and (max-width: 767px) {
            height: calc(100dvh - 482px);
        }
    }



    .rce-input-textarea::-webkit-scrollbar {
        display: none;
    }

    .rce-container-input {
        background-color: #F0F0F0;
        border-radius: 5px;
    }

    .rce-input-textarea {
        height: 50px;
        background-color: #F0F0F0;
        font-weight: 300;
    }

    .rce-mbox-photo--img {
        img {
            width: 150px;
            height: 30px;
            object-fit: contain;
            object-position: left;
            min-height: 30px;
        }
    }

    .audio-recorder {
        border-right: 1px solid #ccc;
        border-radius: 0;
        height: 24px;
        box-shadow: unset !important;
    }

    .audio-wrap {
        width: 100%;
        position: relative;
        left: 0;

        .mic-btn-wrap {
            background-color: white;
            text-align: center;
            height: 44px;
            padding-bottom: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10px;
            font-size: 14px;
            font-weight: bold;

            %common-border {
                border-radius: 20px;
                box-shadow: 0 2px 6px 0 #ccc;
                background: white;
            }

            .start-conv {
                border: none;
                padding: 5px;
                font-weight: 300;
                width: 180px;
                height: 30px;
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 5px;

                @extend %common-border;
            }

            svg {
                font-size: 18px;
            }

            .play-text {
                @extend %common-border;
                font-weight: 300;
                padding: 7px 30px;
                height: 30px;
                display: flex;
                align-items: center;
            }


            .mic-listen {
                height: 30px;
                display: flex;
                align-items: center;
                @extend %common-border;
                padding: 7px 30px;

                button {
                    border: none;
                    background-color: unset;
                    font-weight: 300;
                    margin-left: 5px;
                }

            }

            .mic-mute {
                padding: 20px;
                border-radius: 50%;
                display: inline-block;
                position: relative;

                svg {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }
        }

        .audio-visulaize {
            display: flex;
            background-color: white;
            border-radius: 40px 40px 0 0;
            align-items: center;
            justify-content: space-around;
            padding: 10px 0;

            .play-pause {
                position: absolute;
                cursor: pointer;

                svg {
                    font-size: 32px;
                    background-color: white;
                }
            }
        }

        .play-wrap {
            position: absolute;
            left: 0;
            right: 0;
            margin: auto;
            text-align: center;
            top: 75px;

            button {
                border-radius: 50px;
                background: $themePrimary;
                border: none;
                height: 50px;
                width: 50px;

                svg {
                    color: white;
                }
            }

            svg {
                color: #4886FF;
                font-size: 32px;
            }
        }

        &:before {
            content: "";
            width: calc(100% - 60px);
            left: 0;
            right: 0;
            margin: auto;
            height: 1px;
            position: absolute;
            bottom: 0;
            background-color: #f1f1f1;
        }
    }
}



// Document upload modal
.docUpload {
    text-align: center;

    .docM {
        font-size: 70px;
        margin-bottom: 0px;
        color: #8a8a8a;
        position: relative;
        top: -10px;
    }
}




.formStyle {
    label {
        font-size: 14px;
        margin-bottom: 0;
    }

    input {
        padding: 10px 20px !important;
        border-radius: 30px;
    }

    .PhoneInput {
        position: relative;

        .PhoneInputCountry {
            position: absolute;
            top: 15px;
            left: 20px;
            border-right: 1px solid #ccc;
            padding-right: 10px;
        }

        input {
            padding-left: 80px !important;
        }
    }

    .country-drop {
        &>div {
            border-radius: 30px;
            border-color: #dee2e6;
            overflow: hidden;
        }

        input {
            height: 32px;
        }
    }
}







.deskNavigation {
    .mobileHam {
        display: none;
    }

    .clsIcon {
        display: none;
    }

    .closeIcon {
        .HamIcon {
            display: none;
        }

        .clsIcon {
            display: block;
        }
    }
}

// mobile responsive code 
@media screen and (max-width: 991px) {
    .deskNavigation {
        .mobileHam {
            display: block;
        }

        .voice-drop {
            // display: none !important;

            position: absolute;
            width: 270px;
            background: white;
            border-left: 1px solid #f1f1f1;
            top: 74px;
            right: -270px;
            visibility: hidden;
            flex-direction: column;
            z-index: 99;
            height: calc(100dvh - 74px);
            justify-content: start !important;
            gap: 10px;
            padding: 20px;
            align-items: normal !important;
            transition: .3s;

            a {
                button {
                    width: 100%;
                }
            }

            .agent-dropdown,
            select {
                width: 100% !important;
            }

            @media screen and (max-width: 767px) {
                height: calc(100dvh - 133px);
                top: 133px;
            }
        }

        .active {
            right: 0;
            transition: .3s;
            visibility: visible;
        }
    }

    .home-title {
        display: flex;
        align-items: center;

        .nav-wrap {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }
}

@media screen and (max-width: 600px) {
    .home-wrap {
        .audio-wrap {
            .mic-btn-wrap {
                .start-conv {
                    width: 50px;
                }
            }
        }
    }

    .d-none-sm {
        display: none;
    }
}



@keyframes flip {
    from {
        transform: rotateY(-180deg);
        opacity: 0;
    }

    to {
        transform: rotateY(0);
        opacity: 1;
    }
}

@keyframes blink {
    0% {
        opacity: 1;
        color: red;
    }

    50% {
        opacity: 0;
        color: red;
    }

    100% {
        opacity: 1;
        color: red;
    }
}