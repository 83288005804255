$primary-color: #4263eb;
$secondary-color: #ffffff;
$text-color: #333;
$border-color: #e9ecef;
$timestamp-color: #6c757d;

.chat-container {
  width: 100%;
  margin: 0 auto;
  height: 80vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  
  .header {
    padding: 1rem;
    border-bottom: 1px solid $border-color;
    background: $secondary-color;
    
    .project-title {
      font-weight: 600;
      color: $text-color;
      display: flex;
      align-items: center;
      gap: 0.5rem;
      
      .hashtag {
        color: $primary-color;
      }
    }
    
    .description {
      color: $text-color;
      margin: 0;
    }
  }
  
  .chat-messages {
    flex: 1;
    overflow-y: auto;
    padding: 5rem 2rem 2rem 2rem;
    
    .message {
      margin-bottom: 2rem;
      
      .user-info {
        display: flex;
        align-items: center;
        gap: 1rem;
        margin-bottom: 0.5rem;
        
        .avatar {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          overflow: hidden;
          
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        
        .user-details {
          .name {
            font-weight: 600;
            margin: 0;
          }
          
          .role {
            color: $timestamp-color;
            font-size: 0.9rem;
            margin: 0;
          }
        }
        
        .timestamp {
          margin-left: auto;
          color: $timestamp-color;
          font-size: 0.9rem;
        }
      }
      
      .message-content {
        background: $secondary-color;
        padding: 1rem;
        border-radius: 0.5rem;
        margin-left: 3.5rem;
        
        p {
          margin: 0;
        }
        
        .image-gallery {
          display: flex;
          gap: 1rem;
          margin-top: 1rem;
          
          img {
            width: 200px;
            height: 150px;
            object-fit: cover;
            border-radius: 0.5rem;
          }
        }
      }
    }
  }
  
  .input-area {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 1rem;
    background: white;
    border-top: 1px solid $border-color;
    
    .input-wrapper {
      display: flex;
      gap: 1rem;
      max-width: 1200px;
      margin: 0 auto;
      
      input {
        flex: 1;
        padding: 0.75rem;
        border: 1px solid $border-color;
        border-radius: 0.5rem;
        
        &:focus {
          outline: none;
          border-color: $primary-color;
        }
      }
      
      .action-buttons {
        display: flex;
        gap: 0.5rem;
        
        button {
          background: none;
          border: none;
          cursor: pointer;
          padding: 0.5rem;
          color: $timestamp-color;
          
          &:hover {
            color: $primary-color;
          }
        }
      }
    }
  }
}