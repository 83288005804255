/**
* This file is part of AudioBot Project
* Copyright (C) 2024 W3villa Technologies
* 
* This program is free software: you can redistribute it and/or modify
* it under the terms of the GNU Affero General Public License as
* published by the Free Software Foundation, either version 3 of the License, or
* (at your option) any later version.
* 
* This program is distributed in the hope that it will be useful,
* but WITHOUT ANY WARRANTY; without even the implied warranty of
* MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
* GNU Affero General Public License for more details.
* 
* You should have received a copy of the GNU Affero General Public License
* along with this program. If not, see <https://www.gnu.org/licenses/agpl-3.0.txt>.
*/
.contact-wrap {
    background-color: #f1f1f1;
    min-height: calc(100vh - 63px);

    .thank-wrap {
        padding: 80px 0;
        width: 60%;
        margin: auto;
        text-align: center;
        background-color: white;
        top: 100px;
        position: relative;
        border-radius: 15px;

        .thank-content {
            font-size: 5rem;
            font-weight: 500;
        }

        .tick-wrap {
            width: 200px;
            margin: auto;

            img {
                width: 100%;
            }
        }
    }

    .contact {
        padding-top: 80px;
        padding-bottom: 40px;

        .contactInner {
            border-radius: 20px;
            padding: 40px;
            background-color: white;
            box-shadow: 0 4px 10px 0 #ccc;

            @media screen and (max-width: 767px) {
                padding: 20px;
            }

            .contact-desc {
                position: relative;
                // top: 100px;
                font-size: 18px;
            }

            .form-wrap {
                position: relative;

                label {
                    background: white;
                    position: absolute;
                    top: -12px;
                    left: 12px;
                    color: #474c50;
                    z-index: 9;
                }

                .country-drop {
                    .css-13cymwt-control {
                        &:focus {
                            padding: 8px;
                        }

                        padding: 8px;
                    }

                    .css-1nmdiq5-menu {
                        z-index: 99;
                    }
                }
            }
        }
        @media screen and (max-width: 767px){
            padding-top: 30px;
            padding-bottom: 30px;
        }
        
    }
}
